import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import PostCard from "../components/postcard"

const IndexPage = ({data}) => {
  const posts = data.allMarkdownRemark.nodes

  return(
    <>
      <Layout header={"home"}>
        <SEO title="Home" />
        <Banner />
        <div className="content">
          <div className="container">
            <div className="row">
              {/* <PostCard
                postThumb="https://images.unsplash.com/photo-1560617901-fefdc71a88c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                postTitle="Why Kibbles can kill your dog?"
                postDesc="Kibbles are highly trained specialized killer agents whose sole purpose is to end humanity by eliminating its most beloved friends like dogs. They destroy kidneys and give cancer."
                postAuthor="Author Prasad"
                postDate="3 July, 2020"
                isPrimary={true}
              /> */}

              {posts.map((post, index) => {
                return (
                  <div className="col-md-6 col-sm-12" key={index}>
                    <PostCard
                      postSlug={post.frontmatter.slug}
                      postThumb={post.frontmatter.img_url}
                      postTitle={post.frontmatter.title}
                      postDesc={post.frontmatter.description}
                      postAuthor={post.frontmatter.author}
                      postDate={post.frontmatter.date}
                    />
                  </div>
                )
              })}
              <div className="all-posts-btn col-12">
                <Link to="/posts">
                  <button className="btn">View All Articles &gt;&gt;</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage;

export const pageQuery = graphql`
{
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 20) {
    nodes {
      frontmatter {
        author
        date(formatString: "DD MMMM, YYYY")
        description
        img_url
        slug
        title
      }
    }
  }
}
`
