import React from "react"

const Banner = () => (
  <div className="banner">
		<img src={`/images/banner.png`} alt="Furrble banner" />
    <div className="blog-logo">
      <span className="title">Fables</span>
      <span className="subtitle">Fed by Furrble</span>
    </div>
  </div>
)

export default Banner 
